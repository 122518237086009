<template>
	<section>
		<edit-qcm />
	</section>
</template>
<script>
import EditQcm from '../../../../SuperAdmin/QCM/EditQcm.vue'
export default {
	components: {
		EditQcm
	},
	
}
</script>
